var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:`formulate-input-element formulate-input-element--${_vm.context.class}`,attrs:{"data-type":_vm.context.type}},[_c('div',{staticClass:"mask",class:{ active: _vm.expanded },on:{"click":function($event){_vm.expanded = false}}}),_c('div',{staticClass:"dropdown-outer"},[_c('div',{ref:"dropdown",staticClass:"dropdown",class:{
        active: _vm.expanded,
        error:
          !_vm.context.isValid &&
          _vm.context.showValidationErrors &&
          !_vm.context.disableErrors,
      },attrs:{"tabindex":"0"},on:{"click":_vm.toggle}},[(_vm.context.attributes.disabled || _vm.options.length === 0)?_c('div',{staticClass:"input-mask"}):_vm._e(),_c('font-awesome-icon',{staticClass:"icon-down",attrs:{"icon":"angle-down"}}),(_vm.displayText === 'Canada')?_c('img',{attrs:{"src":"/img/icons/canada.png","alt":"canada flag"}}):_vm._e(),(_vm.displayText === 'United States')?_c('img',{attrs:{"src":"/img/icons/usa.png","alt":"united states flag"}}):_vm._e(),_c('span',{staticClass:"dropdown-inner-text"},[_vm._v(_vm._s(_vm.displayText))])],1),_c('div',{ref:"options",staticClass:"options",class:{ expanded: _vm.expanded }},[_c('div',{staticClass:"options-inner",class:{ expanded: _vm.expanded },on:{"blur":function($event){_vm.expanded = false}}},_vm._l((_vm.options),function(option){return _c('div',_vm._b({key:option.id,staticClass:"option",attrs:{"disabled":!!option.disabled},on:{"click":function($event){return _vm.onSelect(option)}}},'div',option.attributes || option.attrs || {},false),[(option.label === 'Canada')?_c('img',{attrs:{"src":"/img/icons/canada.png","alt":"canada flag"}}):_vm._e(),(option.label === 'United States')?_c('img',{attrs:{"src":"/img/icons/usa.png","alt":"united states flag"}}):_vm._e(),_vm._v(" "+_vm._s(option.label)+" ")])}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }