import { render, staticRenderFns } from "./TTImageInput.vue?vue&type=template&id=36feb111&scoped=true"
import script from "./TTImageInput.vue?vue&type=script&lang=js"
export * from "./TTImageInput.vue?vue&type=script&lang=js"
import style0 from "./TTImageInput.vue?vue&type=style&index=0&id=36feb111&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36feb111",
  null
  
)

export default component.exports